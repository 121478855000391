<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ formData.id !== undefined ? "Edit" : "Create"}} Announcement</h5>
            <form autocomplete="off">
                <b-form-group
                        label-size="sm"
                        label="Title:">
                    <b-form-input size="sm" v-model="formData.title" placeholder="Title"></b-form-input>
                    <error-display v-model="errors" ident="title"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Content:">
                    <vue-editor v-model="formData.content"></vue-editor>
                    <error-display v-model="errors" ident="content"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Text to show on button:">
                    <b-form-input size="sm" v-model="formData.button_text" placeholder="Button text"></b-form-input>
                    <error-display v-model="errors" ident="button_text"></error-display>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox
                            size="sm"
                            v-model="formData.is_compulsory"
                            :value="1"
                            unchecked-value="0"
                    >Force users to acknowledge this announcement
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox
                            size="sm"
                            v-model="formData.is_workspace"
                            :value="1"
                            unchecked-value="0"
                    >Show in workspace
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox
                            size="sm"
                            v-model="formData.is_web"
                            :value="1"
                            unchecked-value="0"
                    >Show in web
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox
                            size="sm"
                            v-model="formData.is_mobile"
                            :value="1"
                            unchecked-value="0"
                    >Show in mobile apps
                    </b-form-checkbox>
                </b-form-group>


                    <b-form-group class="border border-danger rounded p-3">
                        <b-form-checkbox
                                size="sm"
                                class="font-weight-bold"
                                v-model="formData.is_live"
                                :value="1"
                                unchecked-value="0"
                        >Make this announcement live across the selected platforms. We recommend saving and previewing before doing this.
                        </b-form-checkbox>
                    </b-form-group>



                <div class="text-right">
                    <b-button @click="saveModel" :variant="formData.is_live == '1' ? 'danger' : 'success'">{{ formData.is_live == '1' ? "Save and publish (please make sure you have previewed)" : "Save as draft"}}</b-button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import { VueEditor } from "vue2-editor";
    import vSelect from "vue-select"

    import {
        announcementDetail,
        announcementSave,
        faqDetail,
        faqSave,
        nationalityGroupDetail,
        nationalityGroupSave, supportCategories,
    } from "../../modules/api/endpoints";
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: "AnnouncementEdit",
        components: {"v-select": vSelect, ErrorDisplay,VueEditor},
        props: {
            id: null,
        },
        data: function () {
            return {
                errorLoading : false,
                isLoading: false,
                formData: {},
                errors : {},
            }
        },
        created() {
            if (this.id != null) {
                this.loadModel();
            } else {
                this.formData.button_text = "I've read and understand this announcement";
                this.formData.is_workspace = 1;
                this.formData.is_web = 1;
                this.formData.is_mobile = 1;
                this.formData.is_compulsory = 0;
            }
        },
        methods: {
            loadModel() {
                this.isLoading = true;
                getResource(announcementDetail(this.id)).then((resp) => {
                    this.formData = resp.data.success.data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(announcementSave,this.formData).then((resp) => {
                    this.isLoading = false;
                    this.$router.push({'name' : 'announcements', params: {data_updated : true}}).catch(error => {});
                }).catch((err) => {
                    if(containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
